.variante {
    h2 {
        font: normal normal 700 14px Poppins;
        color: #064C37;
        text-transform: uppercase;
    }

    .variante-title {
        font: normal normal 500 14px Poppins;
        color: #096264;
        text-transform: uppercase;
        margin-bottom: 3px;
        padding-left: 5px;
    }

    select {
        position: relative; // Ajout d'une position relative pour que le pseudo-élément soit positionné par rapport à cet élément
        background: #F7F7F7 0% 0% no-repeat padding-box;
        border: 1px solid #EFEFEF;
        border-radius: 34px;
        font: normal normal 500 13px Poppins;
        letter-spacing: 1.05px;
        color: #096264;
        text-transform: uppercase;
        padding: 2px 10px;
        padding-right: 40px;
        margin-top: 0px;

        option {
            font: normal normal 500 13px Poppins;
            letter-spacing: 1.05px;
            color: #096264;
            text-transform: uppercase;
            padding: 5px;
        }
    }

    button {
        background: #F7F7F7;
        border-radius: 34px;
        font-weight: medium;
        font-size: 14px;
        letter-spacing: 1.05px;
        color: #096264;
        text-transform: uppercase;
        cursor: pointer;
        margin-right: 5px;
        padding: 6px 10px;
        border: none;
        margin-bottom: 3px;
    }

    .active {
        border: 1px solid #096264;
    }

    button:hover {
        border: 1px solid #096264;
    }
}

@media (max-width: 1400px) {
    .variante {
        h2 {
            font: normal normal 700 14px Poppins;
            color: #064C37;
            text-transform: uppercase;
        }

        .variante-title {
            font: normal normal 500 14px Poppins;
            color: #096264;
            text-transform: uppercase;
            margin-bottom: 3px;
            padding-left: 5px;
        }

        select {
            position: relative; // Ajout d'une position relative pour que le pseudo-élément soit positionné par rapport à cet élément
            background: #F7F7F7 0% 0% no-repeat padding-box;
            border: 1px solid #EFEFEF;
            border-radius: 34px;
            font: normal normal 500 13px Poppins;
            letter-spacing: 1.05px;
            color: #096264;
            text-transform: uppercase;
            padding: 2px 10px;
            padding-right: 40px;
            margin-top: 0px;

            option {
                font: normal normal 500 13px Poppins;
                letter-spacing: 1.05px;
                color: #096264;
                text-transform: uppercase;
                padding: 5px;
            }
        }

        button {
            background: #F7F7F7;
            border-radius: 34px;
            font-weight: medium;
            font-size: 14px;
            letter-spacing: 1.05px;
            color: #096264;
            text-transform: uppercase;
            cursor: pointer;
            margin-right: 5px;
            padding: 6px 10px;
            border: none;
            margin-bottom: 3px;
        }

        .active {
            border: 1px solid #096264;
        }

        button:hover {
            border: 1px solid #096264;
        }
    }
}